<template>
  <div class="relatedTest">
    <div class="exan-box">
      <ul class="left">
        <li class="el-icon-caret-right" :class="idx==1?'xz':''" @click="teb(1)">裂隙灯</li>
        <li class="el-icon-caret-right" :class="idx==2?'xz':''" @click="teb(2)">眼压</li>
        <li class="el-icon-caret-right" :class="idx==3?'xz':''" @click="teb(3)">眼底相机</li>
        <li class="el-icon-caret-right" :class="idx==4?'xz':''" @click="teb(4)">角膜地形图</li>
        <li class="el-icon-caret-right" :class="idx==5?'xz':''" @click="teb(5)">眼生物测量</li>
        <li class="el-icon-caret-right" :class="idx==6?'xz':''" @click="teb(6)">角膜内皮镜</li>
        <li class="el-icon-caret-right" :class="idx==7?'xz':''" @click="teb(7)">泪膜检查</li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li style="text-align: center;"><el-button type="primary" @click="jcjl">检查结论</el-button></li>
      </ul>
      <div class="rightp" ref="boxm">
        <Liexian ref="LXD" :types="1" class="border-b"></Liexian>

        <IntraocularPressure :type="1" class="border-b"></IntraocularPressure>

        <EyeCamera class="border-b"></EyeCamera>

        <CorneaMap class="border-b"></CorneaMap>

        <EyeBiologyTest class="border-b"></EyeBiologyTest>

        <CorneaNPJ class="border-b"></CorneaNPJ>

        <AppearsTest class="border-b"></AppearsTest>

      </div>
    </div>
    <el-button class="add" type="primary" @click="saves">{{bt}}保存</el-button>
    <el-dialog
        title=" "
        :visible.sync="dialogVisible"
        width="50%"
        >
      <div class="mg">
        <p>依从性评估</p>
        <el-input
            class="mg-sr"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 6}"
            placeholder="请输入内容"
            v-model="textarea2">
        </el-input>
      </div>
      <div class="mg">
        <p>检查结论与建议</p>
        <el-input
            class="mg-sr"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 6}"
            placeholder="请输入内容"
            v-model="textarea3">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="bc">保存</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import specialSurveys from "@/publicFn/specialSurveys";

export default {
  name: "relatedTest",
  data() {
    return {
      idx: 1,
      allys: [],
      saveMap: new Map(),
      maps: new Map(),
      bt: '裂隙灯',
      dialogVisible: false,
      textarea2: '',
      textarea3: ''
    }
  },
  watch: {
    idx(n, o) {
      this.bt = this.maps.get(n)
    }
  },
  mounted() {

    this._api.eyeTest.getBaseTest()
        .then(res => {
          // console.log(res)
          if (res.GetListResult && res.GetListResult.length) {
            let obj = res.GetListResult[0]
            obj.S_OP_Json = this._clJson(obj.S_OP_Json)
            obj.S_OP_Json.S_OP_EC_ChooseId = this.$store.state.physicianVisits.xzConsulting
            this.$store.commit('eyeTest/uplxd', JSON.parse(JSON.stringify(obj)))
          }
        })


    this.$refs.boxm.addEventListener('scroll', this._jl(this.boxbh, 300))
    this.allys = document.getElementsByClassName('border-b')
    this.maps.set(1, '裂隙灯')
    this.maps.set(2, '眼压')
    this.maps.set(3, '眼底相机')
    this.maps.set(4, '角膜地形图')
    this.maps.set(5, '眼生物测量')
    this.maps.set(6, '角膜内皮镜')
    this.maps.set(7, '泪膜检查')

    this.saveMap.set(1, specialSurveys.saveEyeBuTest)
    this.saveMap.set(2, specialSurveys.saveEyeYa)
    this.saveMap.set(3, this._api.eyeTest.saveEyeCamera)
    this.saveMap.set(4, this._api.eyeTest.saveCorneaMap)
    this.saveMap.set(5, this._api.eyeTest.saveEyeBiologyTest)
    this.saveMap.set(6, this._api.eyeTest.saveCorneaNPJ)
    this.saveMap.set(7, this._api.eyeTest.saveAppearsTest)

    this.cshTime("contact_relativeCheck")
  },
  methods: {
    teb(idx) {
      this.idx = idx
      if (idx <= 3) {
        this.$refs.boxm.scrollTop = this.getDivH(idx-1, 0)
      }else if (idx > 3) {
        this.$refs.boxm.scrollTop = this.getDivH(idx-1, 0) + idx * 50
      }
    },
    boxbh() {
      let top = this.$refs.boxm.scrollTop
      this.clScrollTop(top)
    },
    getDivH(idx, type=1)  {
      if (idx < 1) return 0
      let h = 0
      if (type) {
        for (let i = 0; i <= idx; i++) {
          h = h + Number(this.allys[i].offsetHeight)
        }
      }else {
        for (let i = 0; i < idx; i++) {
          h = h + Number(this.allys[i].offsetHeight)
        }
      }
      return h
    },

    clScrollTop(h) {
      if (h < this.allys[0].offsetHeight*0.7) {
        this.idx = 1
      }
      else if (h > this.allys[0].offsetHeight*0.7 && h < this.getDivH(1)) {
        this.idx = 2
      }
      else if (h > this.getDivH(1, 0) && h < this.getDivH(2)) {
        this.idx = 3
      }
      else if (h > this.getDivH(2, 0) && h < this.getDivH(3)) {
        this.idx = 4
      }
      else if (h > this.getDivH(3, 0) && h < this.getDivH(4)) {
        this.idx = 5
      }
      else if (h > this.getDivH(4, 0) && h < this.getDivH(5)) {
        this.idx = 6
      }
      else if (h > this.getDivH(5, 0) && h < this.getDivH(6)) {
        this.idx = 7
      }
    },

    saves() {
      if (this.idx == 1) {
        this.$refs.LXD.save()
        return
      }
      if (this.saveMap.get(this.idx)) {
        this.saveMap.get(this.idx)()
      }
    },

    bc() {
      this.dialogVisible = false
      if ( this.$store.state.Time.timeObj.contact_relativeConclution.ENTER != 0) {
        let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.contact_relativeConclution.ENTER)/1000 + Number(this.$store.state.Time.timeObj.contact_relativeConclution.EXIT)
        this._api.publicApi.addTime('contact_relativeConclution', times)
        this.jsTime('contact_relativeConclution', 1)
      }
      this.$alert('保存成功！', '提示')
    },

    jcjl() {
      this.dialogVisible = true
      this.cshTime("contact_relativeConclution")
    },

    cshTime(keys) {
      // console.log(this.$store.state.Time.timeObj[keys].EXIT)
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    }
  },
  components: {
    Liexian: () => import('@/components/specialSurveys/eyePart/liexian'),
    IntraocularPressure: () => import('@/views/specialSurvey/intraocularPressure'),
    EyeCamera: () => import('@/views/specialSurvey/eyeCamera'),
    CorneaMap: () => import('@/views/specialSurvey/corneaMap'),
    EyeBiologyTest: () => import('@/views/specialSurvey/eyeBiologyTest'),
    CorneaNPJ: () => import('@/views/specialSurvey/corneaNPJ'),
    AppearsTest: () => import('@/views/specialSurvey/appearsTest'),
  },
  beforeDestroy() {
    if ( this.$store.state.Time.timeObj.contact_relativeCheck.ENTER != 0) {
      let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.contact_relativeCheck.ENTER)/1000 + Number(this.$store.state.Time.timeObj.contact_relativeCheck.EXIT)
      this._api.publicApi.addTime('contact_relativeCheck', times)
      this.jsTime('contact_relativeCheck', 1)
    }
  }
}
</script>

<style scoped lang="scss">
.border-b {
  border-bottom: 1px dotted #5a5959;
  padding-bottom: 0.3rem;
  margin-bottom: 1rem !important;
}
.exan-box {
  width: 100vw;
  display: flex;
  justify-content: start;
  height: 87vh;
  overflow: hidden;
}
.rightp {
  width: 87vw;
  height: 87vh;
  overflow: hidden;
  overflow-y: auto;
}
.left {
  width: 12vw;
  font-size: 14px;
  text-align: left;
  border-right: 1px solid #bcbaba;
  overflow: hidden;
  overflow-y: auto;
  li {
    padding: 0.2rem 0.2rem;
    display: block;
    cursor: pointer;
    &:hover{
      background: #e7e7e7;
    }
  }
  .titles {background: #40a7f6; color: #ffffff;}
  .xz {background: #e7e7e7}
}
.left::-webkit-scrollbar { width: 0 !important }
.rightp::-webkit-scrollbar { width: 0 !important }
.add {
  position: fixed;
  right: 0.1rem;
  bottom: 3rem;
}
.mg {
  display: flex;
  padding-bottom: .2rem;
  p {width: 8em;padding-right: .1rem;text-align: right;}
  .mg-sr {
    width: 30vw;
  }
}
</style>
